import React from 'react';

import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { GetStaticProps } from 'next/types';

import Container from '@components/global/Container';
import FaqQuestionAndAnswer from '@components/global/FaqQuestionAndAnswer';
import Seo from '@components/global/Seo';
import Black from '@components/pages/features/email-marketing/ai-email-generator/Black';
import { Faq } from '@components/pages/features/email-marketing/ai-email-generator/Faq';
import GenerateTemplate from '@components/pages/features/email-marketing/ai-email-generator/GenerateTemplate';
import Hero from '@components/pages/features/email-marketing/ai-email-generator/Hero';
import imgPNG from '@components/pages/features/email-marketing/ai-email-generator/Hero/assets/lottieEn/1x/img_0.png';
import imgPNG2x from '@components/pages/features/email-marketing/ai-email-generator/Hero/assets/lottieEn/2x/img_0.png';
import imgPNGPL from '@components/pages/features/email-marketing/ai-email-generator/Hero/assets/lottiePl/1x/img_0@1x.png';
import imgPNG2xPL from '@components/pages/features/email-marketing/ai-email-generator/Hero/assets/lottiePl/2x/img_0@2x.png';
import JsonLd from '@components/pages/features/email-marketing/ai-email-generator/JsonLd';
import MainFeatures from '@components/pages/features/email-marketing/ai-email-generator/MainFeatures';
import MarketingBeyond from '@components/pages/features/email-marketing/ai-email-generator/MarketingBeyond';
import OptimizedEmails from '@components/pages/features/email-marketing/ai-email-generator/OptimizedEmails';
import PromoTestimonial from '@components/pages/features/email-marketing/ai-email-generator/PromoTestimonial';
import Resources from '@components/pages/features/email-marketing/ai-email-generator/Resources';
import StartFree from '@components/pages/features/email-marketing/ai-email-generator/StartFree';
import Steps from '@components/pages/features/email-marketing/ai-email-generator/Steps';

import getGlobalProps from '@helpers/getGlobalProps';
import getPageLocales from '@helpers/getPageLocales';
import { PropsWithSeoData } from '@helpers/seo';

import imgSharePL from '@public/pages/features/email-marketing/ai-email-generator/img_share_pl.png';
import imgShare from '@public/pages/features/email-marketing/ai-email-generator/share@1x.png';

import useTranslation from '@hooks/useTranslation';

export default function AiEmailGenerator({ seo }: PropsWithSeoData) {
    const sftVersion = 'ai-email-generator';
    const pageinfo = 'AI_email_generator';
    const { t } = useTranslation('pages/features/email-marketing/ai-email-generator/index');
    const { locale } = useRouter();
    return (
        <>
            <Seo
                title={t('seo.title')}
                description={t('seo.description')}
                ogImage={locale === 'pl' ? imgSharePL.src : imgShare.src}
                additionalLinkTags={
                    locale === 'pl'
                        ? [
                              {
                                  rel: 'preload',
                                  type: 'image/avif',
                                  href: imgPNGPL.src,
                                  as: 'image',
                                  imageSrcSet: `${imgPNG2xPL.src} ${imgPNG2xPL.width}w`,
                                  imageSizes: `(min-width: ${imgPNGPL.width}px) ${imgPNGPL.width}px, 100vw`,
                              },
                          ]
                        : [
                              {
                                  rel: 'preload',
                                  type: 'image/avif',
                                  href: imgPNG.src,
                                  as: 'image',
                                  imageSrcSet: `${imgPNG2x.src} ${imgPNG2x.width}w`,
                                  imageSizes: `(min-width: ${imgPNG.width}px) ${imgPNG.width}px, 100vw`,
                              },
                          ]
                }
                noindex={!seo.robots.index}
                nofollow={!seo.robots.follow}
                canonical={seo.canonical}
            />
            <JsonLd />
            <main>
                <Hero
                    container={<Container />}
                    version={sftVersion}
                    pageinfo={pageinfo}
                />
                <MainFeatures container={<Container />} />
                <PromoTestimonial container={<Container />} />
                <Steps container={<Container />} />
                <GenerateTemplate
                    container={<Container />}
                    version={sftVersion}
                    pageinfo={pageinfo}
                />
                <OptimizedEmails container={<Container />} />
                <Black container={<Container />} />
                <MarketingBeyond container={<Container />} />
                <StartFree
                    container={<Container />}
                    version={sftVersion}
                    pageinfo={pageinfo}
                />
                <Resources container={<Container />} />
                <Faq container={<Container />}>
                    <FaqQuestionAndAnswer
                        question={t('faqQ1')}
                        answer={t('faqA1')}
                    />
                    <FaqQuestionAndAnswer
                        question={t('faqQ2')}
                        answer={t('faqA2')}
                    />
                    <FaqQuestionAndAnswer
                        question={t('faqQ3')}
                        answer={t('faqA3')}
                    />
                    <FaqQuestionAndAnswer
                        question={t('faqQ4')}
                        answer={t('faqA4')}
                    />
                </Faq>
            </main>
        </>
    );
}

export const getStaticProps: GetStaticProps = async (context) => {
    const globalProps = await getGlobalProps(context, '/features/email-marketing/ai-email-generator');
    globalProps.isSeasonalPromoActive = false;

    return {
        props: globalProps,
        revalidate: 5 * 60,
    };
};

export const getStaticPaths = async () => {
    const locales = getPageLocales('/features/email-marketing/ai-email-generator');
    return {
        paths: locales.map((locale: any) => ({
            params: {
                locale,
            },
        })),
        fallback: false,
    };
};
